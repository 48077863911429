<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">รายการปรับปรุงสต๊อก</h4>
      </div>
      <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="3">
            <v-text-field
              v-model="filter_search"
              label="พิมพ์คำค้นหา"
              single-line
              hide-details
            >
              <v-icon
                slot="prepend"
                color="#182839"
              >
                mdi-magnify
              </v-icon>
            </v-text-field>
          </v-col>
          <v-spacer/>
          <div class="d-flex align-end justify-end">
            <v-btn
              :loading="loader"
              :disabled="loader"
              color="blue-grey"
              class="my-3 white--text"
              @click="gotocreate"
            >
              <v-icon
                left
                dark
              >
                mdi-plus-circle
              </v-icon>
              สร้างรายการปรับปรุงสต๊อก
            </v-btn>
          </div>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="filter_search"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item.stock_costprice="{ item }">
        <div class="text-end">{{formatThaiBaht(item.stock_costprice)}}</div>
      </template>
      <template v-slot:item.stock_date="{ item }">
        {{moment(item.stock_date).add(543, 'year').format('DD/MM/YYYY')}}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          @click="viewItem(item)"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="fetchData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      loader: null,
      loaddata: false,
      cansave: true,
      filter_search: '',
      data: [{
        _id: '000000',
        stock_no: 'ST00017',
        stock_costprice: 450,
        stock_date: moment(),
        stock_lastupdate_by: 'Admin_WG001'
      }, {
        _id: '000001',
        stock_no: 'ST00018',
        stock_costprice: 50,
        stock_date: moment(),
        stock_lastupdate_by: 'Admin_WG001'
      }, {
        _id: '000002',
        stock_no: 'ST00019',
        stock_costprice: 1450,
        stock_date: moment(),
        stock_lastupdate_by: 'Admin_WG001'
      }, {
        _id: '000003',
        stock_no: 'ST00020',
        stock_costprice: 350,
        stock_date: moment(),
        stock_lastupdate_by: 'Admin_WG001'
      }, {
        _id: '000004',
        stock_no: 'ST00021',
        stock_costprice: 550,
        stock_date: moment(),
        stock_lastupdate_by: 'Admin_WG001'
      }, {
        _id: '000005',
        stock_no: 'ST00022',
        stock_costprice: 400,
        stock_date: moment(),
        stock_lastupdate_by: 'Admin_WG001'
      }, {
        _id: '000006',
        stock_no: 'ST00023',
        stock_costprice: 450,
        stock_date: moment(),
        stock_lastupdate_by: 'Admin_WG001'
      }, {
        _id: '000007',
        stock_no: 'ST00025',
        stock_costprice: 8000,
        stock_date: moment(),
        stock_lastupdate_by: 'Admin_WG001'
      }, {
        _id: '000008',
        stock_no: 'ST00026',
        stock_costprice: 4500,
        stock_date: moment(),
        stock_lastupdate_by: 'Admin_WG001'
      }, {
        _id: '000009',
        stock_no: 'ST00030',
        stock_costprice: 3400,
        stock_date: moment(),
        stock_lastupdate_by: 'Admin_WG001'
      }, {
        _id: '000010',
        stock_no: 'ST00034',
        stock_costprice: 3000,
        stock_date: moment(),
        stock_lastupdate_by: 'Admin_WG001'
      }]
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'เลขที่ปรับปรุงสต๊อก',
          align: 'center',
          sortable: false,
          value: 'stock_no'
        }, {
          text: 'ราคาทุน (บาท)',
          align: 'center',
          sortable: false,
          value: 'stock_costprice'
        }, {
          text: 'วันที่ปรับปรุงสต๊อก',
          align: 'center',
          sortable: false,
          value: 'stock_date'
        }, {
          text: 'ผู้ปรับปรุงสต๊อก',
          align: 'center',
          value: 'stock_lastupdate_by',
          sortable: false
        }, {
          text: 'รายละเอียด',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    filterOnlyCapsText (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },
    formatThaiBaht (num) {
      var p = num.toFixed(2).split('.')
      return p[0].split('').reverse().reduce((acc, num, i, orig) => {
        return num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc
      }, '') + '.' + p[1]
    },
    async fetchData () {
      const self = this
      self.loaddata = true
      setTimeout(() => {
        self.loaddata = false
      }, 200)
    },
    viewItem (item) {
      const self = this
      // self.loader = 'loader'
      self.loaddata = true
      setTimeout(() => {
        // self.loader = null
        self.loaddata = false
        self.$router.push({ name: 'DetailStockAdjustScreen', params: { stockItems: item } })
      }, 200)
    },
    gotocreate () {
      const self = this
      // self.loader = 'loader'
      self.loaddata = true
      setTimeout(() => {
        // self.loader = null
        self.loaddata = false
        self.$router.push({ name: 'CreateStockAdjustScreen' })
      }, 200)
    }
  }
}
</script>
